import { useEffect } from 'react'
import {
  useLocation,
  useNavigationType,
  createRoutesFromChildren,
  matchRoutes,
  createBrowserRouter,
} from 'react-router-dom'
import * as Sentry from '@sentry/react'
import constants from 'utils/constants'
import { Website } from 'types/common'

const commonIntegrations = [
  Sentry.reactRouterV6BrowserTracingIntegration({
    useEffect,
    useLocation,
    useNavigationType,
    createRoutesFromChildren,
    matchRoutes,
  }),
]

const integrationByWebsite = {
  agency: commonIntegrations.concat([Sentry.replayIntegration()]),
  employee: commonIntegrations,
  admin: commonIntegrations,
}

export const initSentry = (website: Website) => {
  if (!constants.SENTRY_ENABLED) return
  Sentry.init({
    environment: constants.SENTRY_ENVIRONMENT,
    release: constants.SENTRY_RELEASE,
    dsn: constants.SENTRY_DSN,
    integrations: integrationByWebsite[website],
    tracesSampleRate: parseFloat(constants.SENTRY_TRACES_SAMPLE_RATE),
    replaysSessionSampleRate: parseFloat(constants.SENTRY_REPLAYS_SESSION_SAMPLE_RATE),
    replaysOnErrorSampleRate: parseFloat(constants.SENTRY_REPLAYS_ON_ERROR_SAMPLE_RATE),
  })
  Sentry.setUser(constants.SENTRY_USER)
}

export const wrappedCreateBrowserRouter = constants.SENTRY_ENABLED
  ? Sentry.wrapCreateBrowserRouter(createBrowserRouter)
  : createBrowserRouter
