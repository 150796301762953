import React, { PropsWithChildren, useEffect } from 'react'
import useAgent from 'agency/providers/useAgent'
import { Outlet, useNavigate } from 'react-router-dom'
import httpClient from 'utils/http-client'
import useAgency from 'agency/contexts/AgencyContext'
import { Agency } from 'agency/types/agency'
import { setUserId } from 'utils/amplitude'

type AgentShow = {
  first_name: string
  last_name: string
  email: string
  id: string
  agencies: Agency[]
}

const LoggedInRoutes = ({ children }: PropsWithChildren) => {
  const navigate = useNavigate()
  const { setAgent } = useAgent()
  const { setAgencies } = useAgency()

  useEffect(() => {
    httpClient<AgentShow>('/agent')
      .then((payload) => {
        setAgent({
          firstName: payload.first_name,
          lastName: payload.last_name,
          email: payload.email,
          id: payload.id,
        })

        setAgencies(payload.agencies)
        setUserId(payload.id)
      })
      .catch(() => {
        navigate('/login')
      })
  }, [])

  return children ?? <Outlet />
}

export default LoggedInRoutes
