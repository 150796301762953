import { useEffect } from 'react'
import { useLocation } from 'react-router-dom'
import Appsignal from '@appsignal/javascript'
import { plugin as pluginBreadcrumbsNetwork } from '@appsignal/plugin-breadcrumbs-network'
import { plugin as pluginPathDecorator } from '@appsignal/plugin-path-decorator'
import { plugin as pluginWindowEvents } from '@appsignal/plugin-window-events'
import { captureException } from '@sentry/react'
import constants from 'utils/constants'
import { type Website } from 'types/common'

let appsignal: Appsignal | undefined

export const initAppsignal = (website: Website) => {
  appsignal = new Appsignal({
    key: constants.APPSIGNAL_FRONTEND_MONITORING_KEY,
    revision: constants.VERSION,
    namespace: `frontend_${website}`,
  })
  appsignal.use(pluginBreadcrumbsNetwork())
  appsignal.use(pluginPathDecorator())
  appsignal.use(pluginWindowEvents())
}

export const sendError = (error: unknown) => {
  if (error instanceof Error) {
    appsignal?.sendError(error)
  }
  captureException(error)
}

export const addUserId = (userId: string) => {
  appsignal?.addDecorator((span) => span.setTags({ userId }))
}

export const useBreadcrumbsUpdater = () => {
  const location = useLocation()

  useEffect(() => {
    appsignal?.addBreadcrumb({
      category: 'Navigation',
      action: location.pathname,
    })
  }, [location.pathname])
}
