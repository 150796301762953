import React, { createContext, useContext, useMemo, useState, useEffect } from 'react'
import { Agent } from 'agency/types/agents'
import { addUserId } from 'utils/appsignal'

type AgentContextType = {
  agent?: Agent
  setAgent: (agent: Agent) => void
}

const AgentContext = createContext<AgentContextType>({ agent: undefined, setAgent: () => {} })

export const AgentProvider = ({ children }: React.PropsWithChildren) => {
  const [agent, setAgent] = useState<Agent | undefined>()
  const value = useMemo(() => ({ agent, setAgent }), [agent])
  useEffect(() => addUserId(agent?.id ?? ''), [agent])

  return <AgentContext.Provider value={value}>{children}</AgentContext.Provider>
}

const useAgent = () => useContext(AgentContext)

export default useAgent
